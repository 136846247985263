<template>
	<div id="luru">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">宿舍管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div class="font_14 nav-tab-item" @click="twacherTap(index)" :class="index == teacherIndex ? 'active' : ''" v-for="(item, index) in teacherMeun" :key="index">
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="content" style="padding: 0 15px;">
				<div class="u-f-item" style="padding: 15px 0;">
					<div class="addBar-item" @click="addtap(1)" :class="addIndex == 1 ? 'addBar-active' : ''">学生录入</div>
					<div class="addBar-item" @click="addtap(2)" :class="addIndex == 2 ? 'addBar-active' : ''">班级录入</div>
					<div class="addBar-item" @click="addtap(3)" :class="addIndex == 3 ? 'addBar-active' : ''">宿舍录入</div>
				</div>
				<div class="u-f-item" v-if="addIndex!=3">
					<div class="u-f-item">
						<div style="margin-right: 5px;">年级</div>
						<el-select v-model="grade_id" clearable placeholder="请选择年级" size="small" style="width: 120px;margin-right: 10px;" @change="gradecn1">
							<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item">
						<div style="margin-right: 5px;">班级</div>
						<el-select v-model="calss_id" clearable placeholder="请选择班级" size="small" style="width: 120px;margin-right: 10px;" @change="classcn1">
							<el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<el-button type="primary" size="small" @click="getclassStudent" v-if="addIndex==1">查询</el-button>
				</div>
				<div class="u-f-item" style="margin-top: 10px;" v-if="addIndex==1">
					<div class="u-f-item">
						<div style="margin-right: 5px;">姓名</div>
						<el-input v-model="student_name" size="small" style="width: 120px;margin-right: 10px;"></el-input>
					</div>
				</div>
				<div class="u-f-item" style="margin-top: 10px;" v-if="addIndex!=2">
					<div class="u-f-item">
						<div style="margin-right: 5px;">床位</div>
						<div class="u-f-item" @click="getDormitoryList" style="width: 120px;margin-right: 10px;border: 1px solid #DCDFE6;padding: 3px 0;border-radius: 5px;">
							<input type="text" class="input" disabled v-model="dongName" placeholder="请选择" />
							<i class="el-icon-arrow-down" style="color: #DACFCF;"></i>
						</div>
					</div>
					<div class="u-f-item">
						<div style="margin-right: 5px;">栋</div>
						<div class="u-f-item" style="width: 120px;margin-right: 10px;border: 1px solid #DCDFE6;padding: 3px 0;border-radius: 5px;">
							<input type="text" class="input" disabled v-model="roomName" placeholder="请选择" />
							<i class="el-icon-arrow-down" style="color: #DACFCF;"></i>
						</div>
						<div style="margin-right: 5px;">房</div>
					</div>
					<div class="u-f-item" v-if="addIndex==1">
						<div class="u-f-item" style="width: 120px;margin-right: 10px;border: 1px solid #DCDFE6;padding: 3px 0;border-radius: 5px;">
							<input type="text" class="input" disabled v-model="dormName" />
						</div>
						<div style="margin-right: 5px;">号床</div>
					</div>
				</div>

				<div class="u-f" style="margin-top: 15px;border: 1px solid #EEEEEE;width: 500px;">
					<div class="left">
						<div
							class="u-f-justify"
							style="padding: 8px 0;"
							@click="leftTap(index)"
							:class="typeIndex == index ? 'leftActive' : ''"
							v-for="(item, index) in rewardsTypeList"
							:key="index"
						>
							{{ item.name }}
						</div>
					</div>
					<div class="right">
						<div class="u-f-item u-f-jsb reason" @click="rightTap(item)" :class="item.cheked ? 'rightActive' : ''" v-for="(item, index) in rewardList" :key="index">
							<div style="width: 400rpx;">{{ item.reason }}</div>
							<div v-if="item.val_new.length == 1">
								{{ item.val }}
								<!-- <span v-if="item.val_new.length>1"><i class="el-icon-caret-bottom"></i></span> -->
							</div>
							<div v-if="item.val_new.length > 1">
								<el-dropdown trigger="click">
									<span class="el-dropdown-link">
										{{ item.val }}
										<i class="el-icon-caret-bottom"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item @click.native="openval(index, v)" v-for="(v, i) in item.val_new" :key="i">{{ v }}</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</div>
					</div>
				</div>
			</div>
		</index>

		<!-- 学生选择 -->
		<el-dialog title="选择学生" :visible.sync="studentShow" width="900px" :before-close="handleClose">
			<div class="edit-box student_list">
				<div class="student_item" @click="studentTap(item)" v-for="(item, index) in StudentList" :key="index">{{ item.realname }}</div>
			</div>
		</el-dialog>

		<!-- 楼栋 -->
		<el-dialog title="选择楼栋号" :visible.sync="dormitoryShow" width="900px" :before-close="handleClose">
			<div class="edit-box dormitory_list u-f-justify">
				<div class="dormitory_item" @click="dormitoryTap(item)" v-for="(item, index) in dormitoryList" :key="index">{{ item.name }}</div>
			</div>
		</el-dialog>
		<!-- 宿舍号 -->
		<el-dialog title="选择房号" :visible.sync="roomShow" width="900px" :before-close="handleClose">
			<div class="edit-box student_list">
				<div class="student_item" @click="roomTap(item)" v-for="(item, index) in roomList" :key="index">{{ item.dorm }}</div>
			</div>
		</el-dialog>
		<!-- 床号 -->
		<el-dialog title="选择楼床号" :visible.sync="bedShow" width="900px" :before-close="handleClose">
			<div class="edit-box dormitory_list u-f-justify">
				<div class="dormitory_item" @click="bedTap(item)" v-for="(item, index) in bedList" :key="index">{{ item.bed }}</div>
			</div>
		</el-dialog>
		<!-- 提交 -->
		<el-dialog title="确认提交记录吗？" :visible.sync="addShow" width="600px" :before-close="handleClose">
			<div class="edit-box">
				<div class="u-f-item" style="margin-top: 15px;" v-if="addIndex==1">
					<div>姓名：</div>
					<div>{{student_name}}</div>
				</div>
				<div class="u-f-item" style="margin-top: 15px;" v-if="addIndex==2">
					<div>班级：</div>
					<div>{{className}}</div>
				</div>
				<div class="u-f-item" style="margin-top: 15px;" v-if="addIndex==3">
					<div>宿舍：</div>
					<div>{{dongName}}栋{{roomName}}房</div>
				</div>
				<div class="u-f-item" style="margin-top: 15px;">
					<div>扣分项：</div>
					<div v-if="rewardsTypeList.length">{{rewardsTypeList[typeIndex].name}} {{rewardItem.reason}} {{rewardItem.val}}</div>
				</div>
				<div class="u-f" style="margin-top: 15px;">
					<div>图片：</div>
					<div>
						<el-upload
							class="avatar-uploader"
							action="http://school.blzzxx.cn/v1/school_api/common/upload"
							:show-file-list="false"
							:on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload"
							:headers="headers"
						>
							<img v-if="imageUrl" :src="imageUrl" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
				</div>
				<div class="u-f" style="margin-top: 15px;">
					<div>备注：</div>
					<div><el-input type="textarea" :rows="3" placeholder="备注" v-model="remarks" style="width: 500px;"></el-input></div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="addShow = false" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="addsubmit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			teacherMeun: [
				{ name: '常规录入' },
				{ name: '宿管安排' },
				{ name: '学生安排' },
				{ name: '导出报表' },
				{ name: '预警提示' },
				{ name: '批量嘉奖' },
				{ name: '学生奖惩' },
				{ name: '奖惩录入' }
			],
			teacherIndex: 7,
			addIndex: 1,
			grade_name: '',
			grade_id: '',
			gradeList: [],
			calss_id: '',
			classList: [],
			student_name: '',
			studentShow: false,
			StudentList: [],
			student_id: '',
			dongName: '',
			roomName: '',
			dorm_id:'',
			dormName: '',
			dormitoryList: [],
			dormitoryShow: false,
			roomShow: false,
			roomList: [],
			bedShow: false,
			bedList: [],
			rewardsTypeList: [],
			rewardList: [],
			typeIndex: 0,
			addShow: false,
			remarks:'',
			imageUrl: '',
			action:'/common/upload',
			headers:{
				token:window.localStorage.getItem("token")
			},
			image:'',
			rewardItem:'',
			className:''
		};
	},
	mounted() {
		this.getgradeList();
		this.getrewardsTypeIndex();
	},
	methods: {
		addsubmit(){
			let data = {
				type:this.addIndex,
				rewards_id:this.rewardItem.id,
				val:this.rewardItem.val,
				rewards_classify_id:this.rewardsTypeList[this.typeIndex].id
			}
			if(this.addIndex==1){
				if(this.student_id==''||!this.student_id){return this.$message.error('请选择学生')}
				data.student_id = this.student_id;
			}else if(this.addIndex==2){
				if(this.calss_id==''||!this.calss_id){return this.$message.error('请选择班级')}
				data.class_id = this.calss_id;
			}else if(this.addIndex==3){
				console.log(this.dorm_id)
				if(this.dorm_id==''||!this.dorm_id){return this.$message.error('请选择宿舍')}
				data.dorm_id = this.dorm_id;
			}
			if(this.imageUrl){data.image = this.imageUrl}
			if(this.remarks){data.remarks = this.remarks}
			this.$api.setting.checkRegistration(data).then(res => {
				if (res.data.code == 1) {
					this.$message.success('提交成功');
					this.remarks = '';
					this.imageUrl = '';
					this.addShow = false;
				}else{
					this.$message.error(res.data.msg)
				}
			});
		},
		handleAvatarSuccess(res, file) {
			if(res.code==1){
				this.imageUrl = res.data.fullurl;
				this.image = res.data.url;
			}else{
				this.$message.error(res.msg)
			}
			
		},
		beforeAvatarUpload(file) {
			console.log(file);
		},
		rightTap(item) {
			if(this.addIndex==1&&!this.student_id){return this.$message.error('请先填写完整的学生信息')}
			if(this.addIndex==2&&!this.calss_id){return this.$message.error('请先选择年级班级')}
			if(this.addIndex==3&&!this.dorm_id){return this.$message.error('请先选择宿舍')}
			this.rewardItem = item;
			this.addShow = true;
			
		},
		openval(index, val) {
			this.rewardList[index].val = val;
		},
		leftTap(index) {
			this.typeIndex = index;
			this.rewardsConfig();
		},
		getrewardsTypeIndex() {
			this.$api.setting
				.rewardsTypeIndex({
					filter: JSON.stringify({ type: this.addIndex })
				})
				.then(res => {
					if (res.data.code == 1) {
						this.rewardsTypeList = res.data.data.rows;
						if (res.data.data.rows.length) {
							this.rewardsConfig();
						} else {
							this.rewardList = [];
						}
					}
				});
		},
		rewardsConfig() {
			this.$api.setting
				.RewardsConfigIndex({
					filter: JSON.stringify({ classify_id: this.rewardsTypeList[this.typeIndex].id })
				})
				.then(res => {
					if (res.data.code == 1) {
						let data = res.data.data.rows;
						let arr = [];
						for (let i in data) {
							data[i].cheked = false;
						}
						this.rewardList = data;
					}
				});
		},
		getDormitoryList() {
			this.$api.setting.dormitoryList({}).then(res => {
				if (res.data.code == 1) {
					this.dormitoryList = res.data.data;
					this.dormitoryShow = true;
				}
			});
		},
		getroomList(id) {
			this.$api.setting
				.dormindex({
					filter: JSON.stringify({ dormitory_id: id }),
					op: { dormitory_id: 'in' }
				})
				.then(res => {
					if (res.data.code == 1) {
						this.roomList = res.data.data.rows;
						this.roomShow = true;
					}
				});
		},
		getbedIndex(id) {
			this.$api.setting
				.bedIndex({
					filter: JSON.stringify({ dorm: id }),
					op: { dorm: 'in' }
				})
				.then(res => {
					if (res.data.code == 1) {
						this.bedList = res.data.data.rows;
						this.bedShow = true;
					}
				});
		},
		handleClose(done) {
			done();
		},
		addtap(index) {
			this.typeIndex = 0;
			this.addIndex = index;
			this.getrewardsTypeIndex();
		},
		twacherTap(index) {
			this.teacherIndex = index;
			this.teacherIndex = index;
			if (this.teacherIndex == 0) {
				this.$router.push('/dorm');
			} else if (this.teacherIndex == 1) {
				this.$router.push('/dorm1');
			} else if (this.teacherIndex == 2) {
				this.$router.push('/dorm2');
			} else if (this.teacherIndex == 3) {
				this.$router.push('/daochu');
			} else if (this.teacherIndex == 4) {
				this.$router.push('/yujing');
			} else if (this.teacherIndex == 5) {
				this.$router.push('/plliang');
			} else if (this.teacherIndex == 6) {
				this.$router.push('/xuesheng');
			} else if (this.teacherIndex == 7) {
				this.$router.push('/dormAdd');
			}
		},
		// 获取年级列表
		getgradeList() {
			this.$api.setting.getGradeList({}).then(res => {
				if (res.data.code == 1) {
					this.gradeList = res.data.data.rows;
				}
			});
		},
		// 获取班级列表
		getClassList(name) {
			this.$api.setting
				.getClassList({
					filter: JSON.stringify({ grade_id: this.grade_id })
				})
				.then(res => {
					this.classList = res.data.rows;
					if (name) {
						for (let i in this.classList) {
							if (this.classList[i].name == name) {
								this.calss_id = this.classList[i].id;
							}
						}
						console.log(this.calss_id);
					} else {
						this.calss_id = '';
					}
				});
		},
		gradecn1() {
			this.getClassList();
		},
		classcn1(e) {
			for(let i in this.classList){
				if(this.classList[i].id==this.calss_id){
					this.className = this.classList[i].class_name;
				}
			}
			// className
		},
		getclassStudent() {
			if (!this.calss_id) {
				return this.$message.error('请先选择年级班级');
			}
			this.$api.setting
				.classStudent({
					class_id: this.calss_id
				})
				.then(res => {
					if (res.data.code == 1) {
						this.StudentList = res.data.data;
						this.studentShow = true;
					}
				});
		},
		studentTap(item) {
			this.student_name = item.realname;
			this.student_id = item.id;
			this.dongName = item.name;
			this.roomName = item.dorm;
			this.dormName = item.bed;
			this.studentShow = false;
		},
		dormitoryTap(item) {
			this.dongName = item.name;
			this.dormitoryShow = false;
			this.getroomList(item.id);
		},
		roomTap(item) {
			this.roomName = item.dorm;
			this.dorm_id = item.id;
			this.roomShow = false;
			if(this.addIndex==1){
				this.getbedIndex(item.id);
			}
		},
		bedTap(item) {
			console.log(item);
			this.$api.setting
				.studentList({
					bed_id: item.id
				})
				.then(res => {
					if (res.data.code == 1) {
						this.student_name = res.data.data.realname;
						this.student_id = res.data.data.id;
						this.dormName = item.bed;
						// this.grade_name = res.data.data.grade_name;
						// this.class_name = res.data.data.class_name;
						this.getgradeList();
						for (let i in this.gradeList) {
							if (this.gradeList[i].name == res.data.data.grade_name) {
								this.grade_id = this.gradeList[i].id;
							}
						}
						this.getClassList(res.data.data.class_name);
						this.bedShow = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		}
	}
};
</script>

<style lang="scss">
#luru {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 15px;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 78vh;
		.addBar-item {
			background: #efefef;
			margin-right: 10px;
			padding: 5px 20px;
			border-radius: 5px;
			cursor: pointer;
		}
		.addBar-active {
			background-color: #63acff;
			color: #ffffff;
		}
		.input {
			border: none;
			padding: 5px 15px;
			width: 65px;
			background-color: #ffffff;
		}
		input::-webkit-input-placeholder {
			color: #c0c4cc;
		}
		input::-moz-input-placeholder {
			color: #c0c4cc;
		}
		input::-ms-input-placeholder {
			color: #c0c4cc;
		}
		.left {
			width: 130px;
			border-right: 1px solid #eeeeee;
			height: 57vh;
			overflow-y: auto;
		}
		.leftActive {
			background-color: #409eff;
			color: #ffffff;
		}
		.right {
			width: 360px;
			padding: 0 10px;
			height: 57vh;
			overflow-y: auto;
			.reason {
				padding: 8px 5px;
				cursor: pointer;
				border: 1px solid #eeeeee;
				margin-top: 5px;
				border-radius: 5px;
				cursor: pointer;
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.input-item {
					.el-select .el-input.is-disabled .el-input__inner {
						cursor: default;
					}
					.el-input.is-disabled .el-input__inner {
						background-color: #ffffff;
						cursor: default;
					}
					.el-input__prefix,
					.el-input__suffix {
						display: none;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
		.update_box {
			padding: 40px 20px;
			.update_item {
				background-color: #f6f8fb;
				padding: 20px;
				a {
					color: #007aff;
					text-decoration: none;
				}
			}
			.upload-file {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}
			.file_box {
				padding: 5px;
				.el-icon-circle-check {
					display: block;
				}
				.el-icon-circle-close {
					display: none;
				}
			}
			.file_box:hover {
				color: #007aff;
				background-color: #f6f8fb;
				.el-icon-circle-check {
					display: none;
				}
				.el-icon-circle-close {
					display: block;
				}
			}
		}
		.student_list {
			display: flex;
			flex-wrap: wrap;
			padding-top: 15px;
		}
		.student_item {
			padding: 5px 15px;
			margin-right: 10px;
			margin-bottom: 10px;
			border: 1px solid #659de7;
			color: #659de7;
			border-radius: 5px;
			cursor: pointer;
		}
		.dormitory_list {
			padding-top: 15px;
		}
		.dormitory_item {
			width: 80px;
			height: 80px;
			line-height: 80px;
			text-align: center;
			margin-right: 10px;
			margin-bottom: 10px;
			border: 1px solid #659de7;
			color: #659de7;
			border-radius: 5px;
			cursor: pointer;
			font-size: 20px;
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}

.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.el_option {
	display: none !important;
}
.el-dropdown-menu {
	max-height: 450px;
	overflow-y: auto;
	overflow-x: hidden;
}
</style>
